import {ManagerOptions, Socket, SocketOptions} from 'socket.io-client';
import io from 'socket.io-client';
import {
    AcceptResponse,
    CancelResponse,
    ClientToServerEvents,
    DialRequest,
    GetCallResponse,
    PresenceResponse,
    RejectResponse,
    ServerToClientEvents,
    ServerToClientNegativeEnvelope,
    ServerToClientPositiveEnvelope,
} from '../types/socket-io';
import {Presence} from '../models/Presence';

class PresenceClientError extends Error {
    public type: string;
    public code: number;
    public data: any;
    constructor(message: string, type: string, code: number, data?: any) {
        super(message);
        this.type = type;
        this.code = code;
        this.data = data;
    }
}

function toError(
    payload: ServerToClientPositiveEnvelope | ServerToClientNegativeEnvelope,
) {
    if (payload.success === false) {
        return new PresenceClientError(
            payload.error.message,
            payload.error.type,
            payload.error.code,
            payload.error.data,
        );
    }
    return undefined;
}

function handleEmitResponse(
    topic: string,
    response: ServerToClientNegativeEnvelope | ServerToClientPositiveEnvelope,
    res: (any) => void,
    rej: (any) => void,
) {
    const error = toError(response);
    if (error) {
        rej(error);
    } else if (response.success) {
        res(response.data);
    } else {
        rej(
            new PresenceClientError(
                'Unclassified Response',
                'UNCLASSIFIED_RESPONSE',
                500,
                {topic},
            ),
        );
    }
}

export default class Client<T = Record<string, any>> {
    public socket: Socket<ServerToClientEvents, ClientToServerEvents>;
    public data: T;

    public constructor(
        path: string,
        options: Partial<ManagerOptions & SocketOptions>,
        data: T,
    ) {
        this.socket = io(path, options);
        this.data = data;
    }

    /**
     * Connect to the Presence Server.
     * Returns a promise that fulfills once the socket connected.
     */
    public async connect() {
        if (this.socket.connected) {
            return;
        }

        let connected = new Promise<void>(res =>
            this.socket.once('connect', res),
        );
        this.socket.connect();
        return connected;
    }

    /**
     * Returns a promise which resolves once the socket connected. If the
     * socket is allready connected, the promise resolves instantly.
     * This is a helper funtion which is mostly used by integrating frontends
     * that optimistically register handlers in advance, and then desire further
     * code execution once the presence client connected
     */
    public async onceConnected(): Promise<void> {
        return this.socket.connected
            ? Promise.resolve()
            : new Promise(res => this.socket.once('connect', res));
    }

    /**
     * Connect to the presence server and immediately go online
     */
    public async connectAndGoOnline() {
        await this.connect();
        const response = await this.getPresence();
        if (response.presence !== 'online') {
            await this.setPresence('online' as Presence.ONLINE);
        }
    }

    /**
     * Disconnect
     */
    public disconnect() {
        const disconnected = new Promise(res =>
            this.socket.once('disconnect', (reason: string) => res(reason)),
        );
        this.socket.disconnect();
        return disconnected;
    }

    /**
     * Obtain the current presence of the user
     */
    public async getPresence() {
        return new Promise<{presence: Presence}>((res, rej) =>
            this.socket.emit('getPresence', response => {
                handleEmitResponse('getPresence', response, res, rej);
            }),
        );
    }

    /**
     * Obtain the current presence of the user
     */
    public async setPresence(presence: Presence) {
        return new Promise<{presence: Presence}>((res, rej) =>
            this.socket.emit('setPresence', {presence}, response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'setPresence'},
                        ),
                    );
                }
            }),
        );
    }

    public onPresenceSet(listener: ServerToClientEvents['presenceSet']) {
        this.socket.on('presenceSet', listener);
    }
    public async oncePresenceSet() {
        return new Promise<PresenceResponse['data']>((res, rej) => {
            this.socket.once('presenceSet', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'presenceSet'},
                        ),
                    );
                }
            });
        });
    }
    public offPresenceSet(listener: ServerToClientEvents['presenceSet']) {
        this.socket.off('presenceSet', listener);
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public onOnline(listener: ServerToClientEvents['online']) {
        this.socket.on('online', listener);
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public offOnline(listener: ServerToClientEvents['online']) {
        this.socket.off('online', listener);
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public async onceOnline() {
        return new Promise<void>(res => {
            this.socket.once('online', res);
        });
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public onOffline(listener: ServerToClientEvents['offline']) {
        this.socket.on('online', listener);
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public offOffline(listener: ServerToClientEvents['offline']) {
        this.socket.off('offline', listener);
    }

    /**
     * @deprecated is not implemented and will be removed later on. Subscribe to presenceSet instead
     */
    public async onceOffline() {
        return new Promise<void>(res => {
            this.socket.once('offline', res);
        });
    }

    public async getCall() {
        return new Promise<GetCallResponse['data']>((res, rej) => {
            this.socket.emit('getCall', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'getCall'},
                        ),
                    );
                }
            });
        });
    }

    public async dial(request: DialRequest) {
        return new Promise((res, rej) => {
            this.socket.emit('dial', request, response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'dial'},
                        ),
                    );
                }
            });
        });
    }

    public onDialed(listener: ServerToClientEvents['dialed']) {
        this.socket.on('dialed', listener);
    }
    public async onceDialed() {
        return new Promise((res, rej) => {
            this.socket.once('dialed', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'dialed'},
                        ),
                    );
                }
            });
        });
    }
    public offDialed(listener: ServerToClientEvents['dialed']) {
        this.socket.off('dialed', listener);
    }

    public async cancelCall() {
        return new Promise<CancelResponse['data']>((res, rej) => {
            this.socket.emit('cancelCall', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'cancelCall'},
                        ),
                    );
                }
            });
        });
    }

    public onCallCanceled(listener: ServerToClientEvents['callCanceled']) {
        this.socket.on('callCanceled', listener);
    }
    public async onceCallCanceled() {
        return new Promise<CancelResponse['data']>((res, rej) => {
            this.socket.once('callCanceled', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'callCanceled'},
                        ),
                    );
                }
            });
        });
    }
    public offCallCanceled(listener: ServerToClientEvents['callCanceled']) {
        this.socket.off('callCanceled', listener);
    }
    public async acceptCall() {
        return new Promise<AcceptResponse['data']>((res, rej) => {
            this.socket.emit('acceptCall', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'acceptCall'},
                        ),
                    );
                }
            });
        });
    }

    public onCallAccepted(listener: ServerToClientEvents['callAccepted']) {
        this.socket.on('callAccepted', listener);
    }
    public async onceCallAccepted() {
        return new Promise<AcceptResponse['data']>((res, rej) => {
            this.socket.once('callAccepted', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'callAccepted'},
                        ),
                    );
                }
            });
        });
    }
    public offCallAccepted(listener: ServerToClientEvents['callAccepted']) {
        this.socket.off('callAccepted', listener);
    }
    public async rejectCall(reason?: string) {
        return new Promise<RejectResponse['data']>((res, rej) => {
            this.socket.emit('rejectCall', {reason}, response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'rejectCall'},
                        ),
                    );
                }
            });
        });
    }

    public onCallRejected(listener: ServerToClientEvents['callRejected']) {
        this.socket.on('callRejected', listener);
    }
    public async onceCallRejected() {
        return new Promise<RejectResponse['data']>((res, rej) => {
            this.socket.once('callRejected', response => {
                const error = toError(response);
                if (error) {
                    rej(error);
                } else if (response.success) {
                    res(response.data);
                } else {
                    rej(
                        new PresenceClientError(
                            'Unclassified Response',
                            'UNCLASSIFIED_RESPONSE',
                            500,
                            {topic: 'callRejected'},
                        ),
                    );
                }
            });
        });
    }
    public offCallRejected(listener: ServerToClientEvents['callRejected']) {
        this.socket.off('callRejected', listener);
    }

    public onCallHalted(listener: ServerToClientEvents['callHalted']) {
        this.socket.on('callHalted', listener);
    }
    public async onceCallHalted() {
        return new Promise<void>(res =>
            this.socket.once('callHalted', () => res()),
        );
    }
    public offCallHalted(listener: ServerToClientEvents['callHalted']) {
        this.socket.off('callHalted', listener);
    }
}
